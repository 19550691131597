import React from "react";
import Layout from "@layouts/Layout";

import BlogTemplate from "./Blog";

const BlogPage = ({ data: initialData }) => {
  const posts = initialData?.posts?.edges.map((item) => item?.node);
  const data = initialData?.markdownRemark?.frontmatter;

  const seo = {
    ...(data?.seo || {}),
    image: data?.seo?.image?.childImageSharp?.fluid?.src,
  };

  return (
    <Layout seo={seo}>
      <BlogTemplate data={{ posts, blogData: data }} />
    </Layout>
  );
};

export default BlogPage;

export const query = graphql`
  query BlogPageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "Blog" } }) {
      frontmatter {
        seo {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        header {
          title
          description
          buttonText
          subTitle
          video {
            name
            publicURL
          }
        }
      }
    }
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "BlogPost" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            templateKey
            # draft
            date(formatString: "MMMM DD, YYYY")
            tags
            featuredpost
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 560, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
