import React from "react";
import Header from "@layouts/Header";
import BlogRoll from "@containers/BlogRoll";

import "./Blog.styles.scss";

const Blog = ({ data }) => {
  const header = data?.blogData?.header || {};
  return (
    <div className="blog">
      <Header {...header} />
      <BlogRoll posts={data?.posts || []} />
    </div>
  );
};

export default Blog;
