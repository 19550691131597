import React from "react";
import Post from "@components/Post";

import "./BlogRoll.styles.scss";

const BlogRoll = ({ posts }) => {
  return (
    <div className="blog-roll">
      <div className="blog-roll__inner container">
        {posts?.map((item) => {
          return <Post slug={item?.fields?.slug} {...item.frontmatter} />;
        })}
      </div>
    </div>
  );
};

export default BlogRoll;
